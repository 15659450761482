import axios from 'axios';

const apiClient = axios.create({
    baseURL: process.env.VUE_APP_API_URL_VALIDATION,
    timeout: 20000,
});

axios.defaults.params = {
  XDEBUG_SESSION_START: 'PHPSTORM',
};
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
axios.defaults.headers.common['Access-Control-Allow-Headers'] = 'X-Requested-With';
axios.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded';

export default {
    async feasibilityCheck(query) {
        console.log("VALIDATION", process.env.VUE_APP_API_URL_VALIDATION);
        return await apiClient.post(`/network/coverage/flte`, {
            request: query,
        });
    },
};
