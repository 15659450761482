import Vue from 'vue';
import Vuex from 'vuex';
import coverageMap from './modules/coverage-map';
console.log('STORE');
Vue.use(Vuex);

const debug = false; //process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
    modules: {
        coverageMap,
    },
    strict: debug,
});
