// Session, not namespaced
export const PURGE_SESSION_KEY = 'deleteSessionKey';
export const SET_SESSION_KEY = 'setSessionKey';
export const SET_ERROR = 'setError';
export const IS_LOADING = 'isLoading';

// Coverage Map
export const COVERAGE_SET = 'coverage/set';
export const PLACE_SET = 'coverage/placeSet';
export const COVERAGE_IS_LOADING = 'coverage/is_loading';
export const COVERAGE_SET_ERRORS = 'coverage/set_errors';
