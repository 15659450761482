import MtnCoverageService from '@/services/mtn-coverage.service.js'
import MtnFeasibilityModel from '@/models/coverage-map/mtn-feasibility.model.js'

import {
    COVERAGE_FETCH,
    UPDATE_PLACE
} from "../actions.type";
import {
    COVERAGE_SET,
    PLACE_SET,
    COVERAGE_IS_LOADING,
    COVERAGE_SET_ERRORS
} from "../mutations.type";

const state = {
    feasibility: new MtnFeasibilityModel(),
    has_error: false,
    exception: null,
    is_loading: false,
    query: null,
    coverage: null,
    place: null
};

const getters = {
    coverage(state) {        
        return state.coverage;
    },
    subscriberPlace(state) {
        return state.place;
    }
};

const actions = {
    [COVERAGE_FETCH]({ commit }, feasibility) {      
        commit(COVERAGE_IS_LOADING, true);                
        MtnCoverageService.feasibilityCheck(feasibility.getQueryString()).then(({            
            data
          }) => {                                   
            console.log("Coverage SET", data.data);
            commit(COVERAGE_SET, data.data);
          })
          .catch((ex) => {                      
            console.log("Coverage SET Errors", ex);
            commit(COVERAGE_SET_ERRORS, {status: true, exception: ex});       
          });
    },
    [UPDATE_PLACE]({commit}, place) {
        commit(PLACE_SET, JSON.stringify(place));
    }
};

const mutations = {
    [COVERAGE_SET](state, coverage) {
        state.coverage = typeof (coverage) != undefined ? coverage : [];
        state.is_loading = false;
    },
    [PLACE_SET](state, place) {
        state.place = place;
    },
    [COVERAGE_IS_LOADING](state, status) {
        state.is_loading = status;
    },
    [COVERAGE_SET_ERRORS](state, error) {
        state.has_error = error.status;
        state.exception = error.exception;
    }
};

export default {
    state,
    getters,
    actions,
    mutations
}