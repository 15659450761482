import BaseModel from '../base.model';

export default class MtnGeometryModel extends BaseModel {

      type= "MultiPolygon";
      coordinates= [];
      name= null;
  
  constructor (data) {
    super();
    if(this.isEmpty(data, name)) return this;    
    this.type= data.type;
    this.coordinates= data.coordinates;
    this.name= name;
  }
}
