import BaseModel from '../base.model';

export default class MtnFeasibilityModel extends BaseModel {

    format_options= "callback:callback_function";
    service= "WMS";
    version= "1.3.0";
    request= "GetFeatureInfo";
    info_format= "text/javascript";
    exceptions= "application/vnd.ogc.se_xml";
    layers= "mtnsi:FLTE-WHOLESALE";
    query_layers= "mtnsi:FLTE-WHOLESALE";
    transparent=true;
    tiled=true;
    feature_count= 100;
    property_name= "CELL_ID,CENTERLAT,CENTERLONG,LTE_TYPE,FREQ,SIGNAL,SLOTS,DISPLAY_COV_MAP,LAST_UPDATE_DATE";
    srs= "EPSG:900913";
    width= 1006;
    height= 500;
    bbox= "3103978.3232876277,-3019427.5672157835,3104579.0705567226,-3019128.985074092";
    i= 1000;
    j= 1000;
  
  constructor (data) {
    super();
    if(this.isEmpty(data)) return this;    
    this.feature_count = data.feature_count;
    this.width= data.width - 1;
    this.height= data.height - 1;
    this.i = this.width;
    this.j = this.height;
    this.bbox= data.bbox;    
  }

  getQueryString() {
    var self = this;
    var query = `
    &format_options=${self.format_options}
    &service=${self.service}
    &version=${self.version}
    &request=${self.request}
    &info_format=${self.info_format}
    &exceptions=${self.exceptions}
    &layers=${self.layers}
    &query_layers=${self.query_layers}
    &feature_count=${self.feature_count}
    &property_name=${self.property_name}
    &transparent=${self.transparent}
    &tiled=${self.tiled}
    &srs=${self.srs}
    &width=${self.width}
    &height=${self.height}
    &bbox=${self.bbox}
    &i=${self.i}
    &j=${self.j}
    `;   
    
    return self.cleanQueryParameters(query);
  }

  cleanQueryParameters(query) {
      query = query.replace(/(\r\n|\n|\r| )/gm, '');
      query = encodeURIComponent(query);
      query = query.replace(/%26/gm, '&');
      query = query.replace(/%3D/gm, '=');
      return query;
  }

}
