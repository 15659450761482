<template>
  <div class="coverage-container">
    <div>
      <label class="autocomplete-container">
        <GmapAutocomplete
          ref="autocomplete"
          :value="addressInput"
          :select-first-on-enter="true"
          :disabled="!isCleared"
          @place_changed="setPlace"          
        ></GmapAutocomplete>
        <button @click="resetAddress()" :disabled="isCleared">CLEAR</button>
      </label>
    </div>
    <div class="map-container" v-if="!is_confirm_service">
      <gmap-map
        ref="coverage_map"
        :center="center"
        :options="options"
        :zoom="zoom"
        style="width:100%;  height: 400px;"
        @bounds_changed="update('bounds', $event)"
        @click="update('click', $event)"
      >
        <gmap-marker
          :key="index"
          v-for="(m, index) in markers"
          :position="m.position"
          @click="center=m.position"
        >
          <gmap-info-window :opened="m.display_window" :options="{maxWidth:'250px'}">
            <div class="address">
              <b>{{m.window_text}}</b>
            </div>
            <button
              class="btn btn-sm btn-primary"
              @click="confirmService('click', $event)"
            >Confirm Fixed-LTE Availability</button>
          </gmap-info-window>
        </gmap-marker>
      </gmap-map>
      <div class="loading" v-if="is_data_loading"></div>
    </div>
    <div class="service-container" v-if="is_confirm_service">
      <div class="result-container" v-if="!is_data_loading">
        <div v-if="is_service_supported">
          <h1>Yay, Great news!</h1>
          <p>Fixed LTE is available at your address.</p>
          <p>Please select one of the available products below, and we will ship a SIM to you soon.</p>
          <p>Lat: <span> {{ center.lat }} </span></p>
          <p>Lng: <span> {{ center.lng }} </span></p>
          <p>bounds: <span> {{ boundbox }} </span></p>
          <p>Address: <span>{{ markers[0].window_text }}</span></p>
        </div>
        <div v-if="!is_service_supported">
          <h1>Oh no!</h1>
          <p>Fixed LTE is currently not available at your address.</p>
          <p>If you would like further assistance, please contact support or search another address.</p>
          <p>Lat: <span> {{ center.lat }} </span></p>
          <p>Lng: <span> {{ center.lng }} </span></p>
          <p>bounds: <span> {{ boundbox }} </span></p>
          <p>Address: <span>{{ markers[0].window_text }}</span></p>
        </div>
        <button class="btn btn-sm btn-primary reset-address" @click="resetAddress()">CLEAR ADDRESS</button>
      </div>
    </div>
  </div>
</template>
<script src="vue-google-maps.js"></script>

<script>
import _ from "lodash";
import { gmapApi } from "vue2-google-maps";
import MtnFeasibilityModel from "@/models/coverage-map/mtn-feasibility.model.js";
import MtnCoverageModel from "@/models/coverage-map/mtn-coverage.model.js";
import { mtnMixin } from "@/mixins/mtnMixin";
import EventBus from "@/components/shared/event-bus";

import store from "@/store";

import { COVERAGE_FETCH, UPDATE_PLACE } from "@/store/actions.type";

function callback_function(response) {  
  EventBus.$emit("coverage_data", response);
}

export default {
  store: store,
  name: "vue-coverage-map",
  components: {},
  mixins: [mtnMixin],
  props: {
    lat: Number,
    lng: Number,
    place: Object
  },
  data() {
    return {
      map: null,
      geocoder: null,
      center: { lat: this.lat, lng: this.lng },
      mapBounds: null,
      width: 0,
      height: 0,
      zoom: 5,
      mapClickedCount: 0,
      options: {
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: true,
        disableDefaultUi: false
      },
      addressInput: "",
      markers: [],
      display_window: true,
      window_text: "",
      is_confirm_service: false,
      is_service_supported: false,
      is_data_loading: false,
      currentPlace: null,
      isCleared: true,
      currentServices: []
    };
  },
  computed: {
    boundbox: function() {
      var self = this;
      if (self.mapBounds != null) {
        var bounds = JSON.parse(JSON.stringify(self.mapBounds));
        var sw = self.degrees2meters(bounds.west, bounds.south);
        var ne = self.degrees2meters(bounds.east, bounds.north);
        return sw.lng + "," + sw.lat + "," + ne.lng + "," + ne.lat;
      }
      return null;
    },
    google: gmapApi
  },
  watch: {
    mapBounds: _.debounce(function() {
      if(this.currentPlace) {
        this.$log.debug("bounds_changed", this.currentPlace);
        //this.checkLocationAvailability();
      }      
    }, 1500)
  },
  created() {
    this.$store.watch(
      (state, getters) => getters.coverage,
      (newValue, oldValue) => { // eslint-disable-line no-unused-vars
        if (newValue !== null) {
          try {
            this.$log.debug("coverage_state_updated", newValue);
            eval(newValue);
          } catch (e) {
            this.is_data_loading = false;
          }
        }
      }
    );
  },
  mounted() {
    var self = this;
    
    //Assign map upon init    
    self.$refs.coverage_map.$mapPromise.then(map => {

      console.log("COVERAGE_MAP_PROMISE", map);
      //Set place if passed, else geolocate if services are available.
      if(self.place) {
        self.setPlace(self.place);
      } else {
        self.geolocate();    
      }

      //assign globally accessible objects
      var geocoder = new google.maps.Geocoder();
      self.map = map;
      self.geocoder = geocoder;

      //Click event to change marker and reverse lookup address location
      self.map.data.addListener("click", function(event) {
        console.log("TEST", event);
        self.update("click", event);
      });

      //Set global style for imported data
      self.map.data.setStyle({
        fillColor: "orange",
        strokeWeight: 0,
        fillOpacity: 0.03
      });
      //self.testLocation(map); //used for testing map api features
    });

    //Listen for external event for coverage_map data. Returned from MTN.
    EventBus.$on("coverage_data", function(data) {
      self.$log.debug("coverage_data", data);
      self.removeMapData();
      //self.map.data.addGeoJson(data);
      self.is_data_loading = false;
      if(self.is_confirm_service) {
        self.$log.debug("is_confirm_service", data);
        var model = new MtnCoverageModel(data);
        self.currentServices = model.feature_summary_list;        
        self.is_service_supported = model.feature_summary_list.length > 0;
        EventBus.$emit("coverage_supported", self.is_service_supported);
      }
      self.is_data_loading = false;
    });
  },
  methods: {
    //Set current place, method used in autocomplete input.
    setPlace(place) {      
      var self = this;
      self.$log.debug("setPlace", place);
      self.currentPlace = place;
      self.addMarker();
      store.dispatch(UPDATE_PLACE, place);
      self.$emit('place_updated', place);      
    },
    //Add Marker on map, based on input address.
    addMarker() {      
      var self = this;
      self.$log.debug("addMarker", self.currentPlace);
      if (self.currentPlace) {
        self.placeMarker(
          self.currentPlace.geometry.location.lat(),
          self.currentPlace.geometry.location.lng()
        );
        self.zoom = 21;
      } else {
        alert(
          "Cannot find the address you are looking for, please contact support."
        );
      }
    },
    placeMarker(lat, lng) {      
      var self = this;
      self.$log.debug("placeMarker", {lat, lng});
      self.markers = [];
      const marker = {
        lat: lat,
        lng: lng
      };
      var current = self.currentPlace;
      self.markers.push({
        position: marker,
        opacity: 1,
        draggable: false,
        enabled: true,
        display_window: true,
        window_text: current.formatted_address
      });
      self.zoom = 20;
      self.center = marker;
      self.is_data_loading = false;
    },
    //Handle update events on map, based on type. Linked to map events.
    update(field, event) {
      var self = this;
      if (field === "reportedCenter") {
        //Update reported center
        self.reportedCenter = {
          lat: event.lat(),
          lng: event.lng()
        };
      } else if (field === "bounds") {
        //Update map width and height for availability check
        if (self.map != null) {                 
          var mapDiv = self.map.getDiv();
          self.width = 800;
          self.height = 400;          
        }
        //Update map bounds for availability check
        self.mapBounds = event;
      } else if (field === "click") {
        //Remove other markers and add marker at this location.
        self.markers = [];
        var lat = event.latLng.lat();
        var lng = event.latLng.lng();
        //Reverse address lookup add place and address
        self.reverseLookup(lat, lng);        
      } else {
        self.$set(self, field, event);
      }
    },
    //Geolocate current position, based on location services
    geolocate: function() {
      var self = this;
      navigator.geolocation.getCurrentPosition(position => {
        self.center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        };
      });
    },
    reverseLookup(lat, lng) {      
      var self = this;
      var latlng = { lat: lat, lng: lng };
      self.geocoder.geocode({ location: latlng }, function(results, status) {
        if (status === "OK") {
          if (results[0]) {
            self.setPlace(results[0]);                        
          } else {
            window.alert("No results found");
          }
        } else {
          window.alert("Geocoder failed due to: " + status);
        }
      });
    },
    //Check current availability based on location.
    checkLocationAvailability(feature_count) {      
      var self = this;      
      self.$log.debug("checkLocationAvailability", feature_count);
      self.is_data_loading = true;
      self.isCleared = false;
      if (self.center) {        
        self.$log.debug("self.center", self.center);        
        var data = {
          feature_count: feature_count ? feature_count : 100,
          width: self.width,
          height: self.height,
          bbox: self.boundbox
        };        
        var model = new MtnFeasibilityModel(data);
        self.$log.debug("MtnFeasibilityModel", data);
        store.dispatch(COVERAGE_FETCH, model);
      }
    },
    confirmService() {
      var self = this;
      self.is_confirm_service = true;
      self.checkLocationAvailability(10);
    },
    resetAddress() {
      var self = this;            
      self.markers = [];      
      self.currentServices = [];      
      self.is_confirm_service = false;
      self.is_service_supported = false;            
      self.isCleared = true;
    },
    removeMapData() {
      var self = this;
      if(self.map){
        self.map.data.forEach(function(feature) {
          self.map.data.remove(feature);
        });
      }      
    },
    //Used for DEBUG test purposes only, not excuted in production. Used to test API features.
    testLocation(map) {
      var self = this;
      var bounds = new google.maps.LatLngBounds();

      bounds.extend(self.meters2degrees(3457124.277837468, -3472927.041880833));
      bounds.extend(
        self.meters2degrees(3458823.8073880095, -3472180.586526588)
      );

      self.map.fitBounds(bounds);
    }
  }
};
</script>