export const mtnMixin = {
    methods: {
        degrees2meters(lon, lat) {
            var x = lon * 20037508.34 / 180;
            var y = Math.log(Math.tan((90 + lat) * Math.PI / 360)) * 20037508.34 / Math.PI;
            return {
                lat: y,
                lng: x                
            }
        },
        meters2degrees(x,y) {
            var lon = x *  180 / 20037508.34 ;
            //thanks magichim @ github for the correction
            var lat = Math.atan(Math.exp(y * Math.PI / 20037508.34)) * 360 / Math.PI - 90; 
            return {
                lat: lat,
                lng: lon                
            }
        }
    }
}