import Vue from 'vue'

import store from './store'

import App from './App.vue'

import VueLogger from 'vuejs-logger';
import * as VueGoogleMaps from 'vue2-google-maps';

const isProduction = process.env.NODE_ENV === 'production';

Vue.config.productionTip = isProduction;

Vue.use(VueLogger, {
  isEnabled: !isProduction,
  showLogLevel : true,
  showMethodName : true,
  showConsoleColors: true
});
Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GA_API_KEY,
    libraries: "places,drawing,visualization",
    region: "ZA"
  },
  installComponents: true
});


Vue.component(
    'coverage-map',
    require('./components/CoverageMapComponent.vue').default
);

new Vue({    
  store,
  render: h => h(App)
}).$mount('#app');
