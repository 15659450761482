import BaseModel from '../base.model';
import MtnFeatureModel from '../coverage-map/mtn-feature.model.js';

export default class MtnCoverageModel extends BaseModel {
    features = [];
    feature_summary_list = [];
    response = null;

    constructor(data) {
        super();
        var self = this;
        if (this.isEmpty(data)) return this;
        data.features.forEach((feature) => {
            var f = new MtnFeatureModel(feature);
            var option = f.lte_type;
            if (
                f.signal.toLowerCase() == 'indr' &&
                f.lte_type.toLowerCase().indexOf('flte') !== -1
            ) {
                console.log('Feature', f);
                if (self.features.indexOf(f) < 0) self.features.push(f);
                if (self.feature_summary_list.indexOf(option) < 0)
                    self.feature_summary_list.push(option);
            }
        });
    }

    callback_function(data) {
        // eslint-disable-line no-unused-vars
        return data;
    }
}
