import BaseModel from '../base.model';
import MtnGeometryModel from './mtn-geometry.model';

export default class MtnFeatureModel extends BaseModel {

    id= null;
    geometry= null;
    cell_id= null;
    center= null;
    lte_type= null;
    freq= null;
    signal= null;
    slots= null;
  
  constructor (data) {
    super();
    if(this.isEmpty(data)) return this;    
    this.id = data.id;
    this.geometry = new MtnGeometryModel(data.geometry, data.geometry_name);
    this.cell_id = data.properties.CELL_ID;
    this.center = { lng:data.properties.CENTERLONG, lat: data.properties.CENTERLAT };
    this.lte_type = data.properties.LTE_TYPE;
    this.freq = data.properties.FREQ;
    this.signal = data.properties.SIGNAL;
    this.slots = data.properties.SLOTS;
  }
}
